import { useMemo } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { Article, Page, Term } from "custom-types/Wordpress";
import useContentEventTracker from "hooks/news/useContentEventTracker";
import createNewsTargetingObject from "utils/createNewsTargetingObject";
import formatWordpressContent from "utils/news/formatWordpressContent";
import slugifyName from "utils/slugifyName";

import styles from "./ArticleContent.module.css";

const ArticleContent: React.FC<{
  className?: string;
  item: Article | Page | Term;
  enableLazyLoad?: boolean;
}> = ({ className, item, enableLazyLoad }) => {
  const { articleContentRef } = useContentEventTracker();

  const { query } = useRouter();

  const displayCarousel =
    !(item as Article).brand?.name && query?.categorySlug !== "leafly-list";

  const { content, disableAds, manualAdPlacement, slug } = item;

  const targeting = createNewsTargetingObject(
    slug,
    (item as Article).primaryCategorySlug || (item as Page)?.categories?.length
      ? slugifyName((item as Page)?.categories[0])?.replace("--", "-")
      : query.slugs?.length
        ? query.slugs[0]
        : undefined,
    (item as Article | Page).tags,
  );

  const formattedContent = useMemo(
    () =>
      formatWordpressContent(
        content,
        disableAds,
        manualAdPlacement,
        displayCarousel,
        targeting,
        enableLazyLoad,
      ),
    [content, disableAds, manualAdPlacement, displayCarousel],
  );

  return (
    <div
      id="article-content"
      ref={articleContentRef}
      className={classNames(className, styles.articleContent)}
    >
      {formattedContent}
    </div>
  );
};

export default ArticleContent;
