// eslint-disable-next-line no-restricted-imports -- Required to parse complex HTML from WP
import { DOMNode, Element } from "html-react-parser";
import dynamic from "next/dynamic";
import Script from "next/script";

import { ReplaceElements } from "custom-types/Wordpress";

import Image from "components/Image";

const LazyTweet = dynamic(() => import("components/news/LazyTweet"));

const NewsAdAdditional = dynamic(
  () => import("components/news/Ads/NewsAdAdditional"),
);
const NewsAdLower = dynamic(() => import("components/news/Ads/NewsAdLower"));
const NewsAdParallax = dynamic(
  () => import("components/news/Ads/NewsAdParallax"),
);
const NewsAdUpper = dynamic(() => import("components/news/Ads/NewsAdUpper"));
const LegalizationMap = dynamic(() => import("components/LegalizationMap"));
const NewsEmailSignup = dynamic(
  () => import("components/news/NewsEmailSignup"),
);
const Video = dynamic(() => import("components/Video"));

let videoEls = 0;
let additionalAds = 0;

export default (
  domNode: DOMNode,
  adTargeting?: Record<string, string | string[] | undefined>,
) => {
  /**
   * Only Element types have the "attribs" property, so we can narrow
   * the type of domNode by checking for its presence.
   */
  if (!("attribs" in domNode)) {
    return;
  }

  const { attribs, type, children, name } = domNode;

  if (name === "img" && attribs.src && !attribs["data-srcset"]) {
    return (
      <Image
        src={attribs.src}
        sizes={[510, 735, 549, 740, null, 510]}
        alt={attribs.alt}
      />
    );
  }

  if (attribs?.class && type === "tag") {
    if (hasClass(attribs.class, ReplaceElements.sailthru)) {
      return <NewsEmailSignup source="article-inline" />;
    }

    if (hasClass(attribs.class, ReplaceElements.jwPlayer)) {
      let videoUrl;

      const videoId =
        attribs["data-video"]?.length === 8 && attribs["data-video"];
      if (videoId) {
        // This method should work for any newer verions of the jwplayer shortcode
        videoUrl = `https://video.leafly.com/v2/media/${videoId}`;
      } else {
        // This method should cover any older verions of the jwplayer shortcode
        const metaTag = children.find(
          (child) =>
            child.type === "tag" &&
            child.name === "meta" &&
            child.attribs?.itemprop &&
            child.attribs?.itemprop === "contentUrl",
        ) as Element;
        if (metaTag) {
          videoUrl = metaTag.attribs?.content?.replace(
            "https://cdn.jwplayer.com/",
            "https://video.leafly.com/",
          );
        }
      }

      if (videoUrl) {
        videoEls = videoEls + 1;

        return (
          <Video
            playerId={`article-video-content-${videoEls}`}
            playerScript="https://video.leafly.com/libraries/QKPVtt8Y.js"
            playlist={videoUrl}
          />
        );
      } else {
        // Since the video element we're looking to replace contains scripts
        // that will error out, we want to get rid of it entirely if we cant
        // get a videoUrl and use our own Video component
        return <></>;
      }
    }

    if (hasClass(attribs.class, ReplaceElements.adShortcode)) {
      const adUnitType = attribs["data-unit"] || "";

      switch (adUnitType) {
        case "additional":
          additionalAds = additionalAds + 1;
          return (
            <NewsAdAdditional index={additionalAds} targeting={adTargeting} />
          );
        case "upper":
          return <NewsAdUpper targeting={adTargeting} />;
        case "middle":
          return <NewsAdParallax targeting={adTargeting} />;
        case "lower":
          return <NewsAdLower targeting={adTargeting} />;
        default:
          // Since the ad element we're looking to replace contains scripts
          // that will error out, we want to get rid of it entirely if we cant
          // get the correct unit and use the GoogleAd component
          return <></>;
      }
    }

    if (hasClass(attribs.class, ReplaceElements.map)) {
      const isLegalizationMap = attribs["data-map"] === "legalization";
      if (isLegalizationMap) {
        return <LegalizationMap />;
      } else {
        return <></>;
      }
    }

    // This block has a very specific structure, so if any of these fail we know we're
    // not dealing with something we expect.
    if (hasClass(attribs.class, ReplaceElements.twitter)) {
      // this should always be an array of one element
      const wrapper = children?.[0] as Element;
      const blockquote = wrapper?.children?.find(
        (child) =>
          (child as Element).name === "blockquote" &&
          (child as Element).attribs.class === "twitter-tweet",
      ) as Element;
      const linkTag = blockquote?.children?.find(
        (child) =>
          (child as Element).name === "a" &&
          (child as Element).attribs.href.indexOf("twitter.com") > -1,
      ) as Element;

      const src = linkTag?.attribs?.href;

      if (src) {
        // ie: https://twitter.com/username/status/12345678987654321?ref_src=xxxxxxxx
        const tweetId = src.substring(
          src.lastIndexOf("/") + 1,
          src.lastIndexOf("?"),
        );

        // tweetId should always be a number, so we make sure its a number
        if (tweetId && tweetId !== "" && isNaN(Number(tweetId))) {
          return <LazyTweet tweetId={tweetId} />;
        }
      }
    }
  }

  if (name === "script" && attribs.id === ReplaceElements.paycor) {
    return (
      <>
        <div id={attribs.id} />
        <Script src={attribs.src} />
      </>
    );
  }
};

function hasClass(classes: string, className: string) {
  return classes.indexOf(className) > -1;
}
