import React from "react";
import dynamic from "next/dynamic";

import { AdElement } from "utils/news/adUtils";

const NewsDispensaryCarousel = dynamic(
  () => import("components/news/NewsDispensaryCarousel"),
);

const MAX_NUMBER_OF_ADS = 12;
const DISPENSARY_CAROUSEL_POSITION = 4;

export default (
  elements: JSX.Element[],
  displayCarousel: boolean,
  adTargeting?: Record<string, string | string[] | undefined>,
  enableLazyLoad?: boolean,
): JSX.Element[] => {
  const elementsWithAds: JSX.Element[] = [];

  // The reason we need two separate counters is due to the dispensary carousel
  // being injected in the same manner as an ad, but it obviously is not an ad.
  // The ad number is critical for determining which ad tag to use in each slog.
  let adNumber = 1;
  let elNumber = 1;
  let nextMinIndex = 0;

  elements.forEach((element, index) => {
    // Background blocks need special treatment
    const isBackgroundBlock =
      element.props?.className?.indexOf("leafly-background-section") > -1;

    if (!isBackgroundBlock) {
      elementsWithAds.push(element);

      // This validates ad position. In the case of the first ad, this ensures
      // that it will always be placed. Some articles are short enough to where
      // the last part of the OR operator would negate placing an ad at all.
      const isValidPosition =
        index >= nextMinIndex &&
        (elNumber === 1 || index < elements.length - 4);

      if (
        adNumber <= MAX_NUMBER_OF_ADS &&
        isValidPosition &&
        canInsert(elements, index)
      ) {
        if (displayCarousel && elNumber + 1 === DISPENSARY_CAROUSEL_POSITION) {
          elementsWithAds.push(<NewsDispensaryCarousel />);
        } else {
          elementsWithAds.push(
            <AdElement
              adNumber={adNumber}
              key={`news-ad-unit-${index}`}
              targeting={adTargeting}
              enableLazyLoad={enableLazyLoad}
            />,
          );
          adNumber = adNumber + 1;
        }

        elNumber = elNumber + 1;
        nextMinIndex = index + 5;
      }
    } else {
      // background block has a child container element and its
      // not worth checking if only 1 element exists within
      const blockElements = element.props?.children?.props?.children;
      if (blockElements?.length > 1) {
        let backgroundNextMinIndex = 0;
        blockElements.forEach(
          (_blockElement: JSX.Element, blockIndex: number) => {
            // Validates position same as above, but we don't want to restrict
            // every block to using the same elements.length as above, so we only
            // use elements.length - 4 if the background block is the last element
            // on the page
            const isValidPosition =
              blockIndex >= backgroundNextMinIndex &&
              blockIndex <
                blockElements.length - (index === elements.length - 1 ? 4 : 1);
            if (
              adNumber <= MAX_NUMBER_OF_ADS &&
              isValidPosition &&
              canInsert(blockElements, blockIndex)
            ) {
              if (
                displayCarousel &&
                elNumber + 1 === DISPENSARY_CAROUSEL_POSITION
              ) {
                element.props.children.props.children.splice(
                  blockIndex + 1,
                  0,
                  <NewsDispensaryCarousel />,
                );
              } else {
                element.props.children.props.children.splice(
                  blockIndex + 1,
                  0,
                  <AdElement
                    adNumber={adNumber}
                    key={`news-ad-unit-block-${blockIndex}`}
                    targeting={adTargeting}
                    enableLazyLoad={enableLazyLoad}
                  />,
                );
                adNumber = adNumber + 1;
              }

              elNumber = elNumber + 1;
              backgroundNextMinIndex = blockIndex + 5;
            }
          },
        );
      }

      elementsWithAds.push(element);
    }
  });

  return elementsWithAds;
};

function canInsert(elements: JSX.Element[], index: number) {
  const isThisElementValid =
    isElementOfType(elements[index], ["p"]) &&
    childIsValidType(elements[index]);

  if (isThisElementValid) {
    const thisElementHasEnoughCharacters = hasEnoughCharacters(elements[index]);

    if (thisElementHasEnoughCharacters) {
      const isNextElementTypeValid = isElementOfType(elements[index + 1], [
        "p",
        "h2",
        "h3",
        "h4",
      ]);

      if (isNextElementTypeValid) {
        const isPreviousElementTypeValid =
          index > 1
            ? !isElementOfType(elements[index - 1], ["blockquote"])
            : true;

        if (isPreviousElementTypeValid) {
          return true;
        }
      }
    }
  }

  return false;
}

// Search passed array of valid element types to see if the element is valid
function isElementOfType(element: JSX.Element, elementTypes: string[]) {
  return elementTypes.some((elementType) => element?.type === elementType);
}

// This is a very round about way of checking the type of the element.
// Using typeof to do this check would have involved a lot more code.
function getChildElementType(element: JSX.Element) {
  return element?.props?.children?.constructor?.name;
}

// If the element is a string and the string length is greater than 130
// characters it returns true. If it has under 130 characters it returns
// false
function hasEnoughCharacters(element: JSX.Element) {
  const childElementType = getChildElementType(element);
  return childElementType === "String"
    ? element?.props?.children.length > 150
    : true;
}

// Verifys that the child is at least not another element
function childIsValidType(element: JSX.Element) {
  const childElementType = getChildElementType(element);
  return childElementType === "String" || childElementType === "Array";
}
