import {
  WP_REST_API_Category,
  WP_REST_API_Post,
  WP_REST_API_Tag,
  WP_REST_API_User,
} from "wp-types";

export enum RegionRestriction {
  CA = "CA",
  US = "US",
  WW = "WW",
}

// not part of wp-types library but seems like its on the way
interface WP_REST_API_menu_items {
  id: number;
  title: { rendered: string };
  status: string;
  url: string;
  attr_title: string;
  description: string;
  type: string;
  type_label: string;
  object: string;
  object_id: number;
  parent: number;
  menu_order: number;
  target: string;
  classes: [string];
  xfn: [string];
  invalid: false;
  meta: [];
  menus: number;
  acf: [];
}

interface HeadlessBase {
  author_full: string;
  author_full_details: {
    description: string;
    image: string;
  };
  categories_full: string[];
  featured_media_full: string | null;
  featured_media_full_details: {
    alt_text: string;
    caption: string;
  };
  script_files: string[];
  style_files: string[];
  tags_full: string[];
}

interface PostHeadlessBase extends HeadlessBase {
  featured_video_id: string;
}

export type Breadcrumb = {
  href: string | null;
  title: string;
};

interface PageHeadlessBase extends HeadlessBase {
  breadcrumbs: Breadcrumb[];
  menu_order: number;
  parent: number;
  top_parent: number | null;
}

interface ACFBase<A = unknown> {
  acf: A;
}
interface SharedACF {
  disable_ads: boolean;
  manual_ad_placement: boolean;
  region_restriction: keyof typeof RegionRestriction;
  restriction_redirect: string | null;
  schema_json: string;
}

interface ArticleACF extends SharedACF {
  article_type: "normal" | "featured";
  featured_media_type: "video" | "image";
  featured_video_caption: string;
  linked_brand_custom_image: string | null;
  linked_brand_id: string;
  linked_brand_image: string;
  linked_brand_name: string;
  linked_brand_url: string;
}

interface PageACF extends SharedACF {
  content_width: "default" | "fullwidth" | "centered";
  enable_hero: boolean;
  hero_alternate_title: string;
  hero_background_color: string;
  hero_background_image_desktop: string;
  hero_background_image_mobile: string;
  hero_background_image_tablet: string;
  hero_cta_button_type: "primary" | "tertiary";
  hero_cta_link: string;
  hero_cta_text: string;
  hero_subtitle: string;
  hero_text_color: string;
  show_sidebar: boolean;
  show_title: boolean;
}

interface YoastBase {
  yoast_head: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export interface BaseResponse<T = any> {
  data: T;
  totalCount: number;
  totalPages: number;
}

export type PostResponse = WP_REST_API_Post &
  PostHeadlessBase &
  ACFBase<ArticleACF> &
  YoastBase;
export type PageResponse = WP_REST_API_Post &
  PageHeadlessBase &
  ACFBase<PageACF> &
  YoastBase;
export type TaxonomyResponse = (WP_REST_API_Tag | WP_REST_API_Category) &
  YoastBase;
export type UserResponse = WP_REST_API_User &
  YoastBase & {
    avatar_image: string;
  };
export type MenuItemsResponse = WP_REST_API_menu_items;
export type TermsResponse = WP_REST_API_Post &
  YoastBase &
  Pick<
    HeadlessBase,
    | "featured_media_full"
    | "featured_media_full_details"
    | "script_files"
    | "style_files"
  > &
  ACFBase<SharedACF>;

export enum ReplaceElements {
  jwPlayer = "jwppp-video-box",
  adShortcode = "leafly-gam-news-unit",
  sailthru = "leafly-sailthru-signup",
  twitter = "wp-block-embed-twitter",
  map = "wp-block-leafly-blocks-leafly-map",
  image = "<img",
  // This is the ID of the script element that loads paycor's careers iframe
  paycor = "gnewtonjs",
}

export type FeaturedMedia = {
  altText: string;
  caption: string;
  id: string;
  type: "video" | "image";
  url: string;
};

export type Author = {
  description: string;
  image: string;
  name: string;
  urlPath: string;
};

type SharedFields = {
  author: Author;
  categories: string[];
  categoryIds?: number[];
  content: string;
  date: string;
  disableAds: boolean;
  id: number;
  link: string;
  manualAdPlacement: boolean;
  modified: string;
  regionRestriction: keyof typeof RegionRestriction;
  regionRestrictionRedirectUrl: string | null;
  schemaJson: string;
  scripts: string[];
  seoHeadContent: string;
  slug: string;
  styles: string[];
  tags: string[];
  title: string;
};

export type Brand = {
  customImage: string | null;
  image: string | null;
  name: string | null;
  url: string | null;
};

export type Article = SharedFields & {
  brand: Brand;
  featuredMedia: FeaturedMedia;
  primaryCategorySlug: string | null;
  isFeatured: boolean;
};

export type ArticleExcerpt = Pick<
  SharedFields,
  "date" | "id" | "link" | "slug" | "title"
> & {
  excerpt: string;
  author: string;
  featuredMedia: Pick<FeaturedMedia, "altText" | "url">;
};

export type Layout = {
  contentWidth: string;
  showSidebar: boolean;
  showTitle: boolean;
};

export type Hero = {
  alternateTitle: string;
  backgroundColor: string;
  ctaLink: string;
  ctaText: string;
  desktopImage: string;
  enable: boolean;
  mobileImage: string;
  subtitle: string;
  tabletImage: string;
  textColor: string;
};

export type Page = SharedFields & {
  breadcrumbs: Breadcrumb[];
  featuredMedia: Pick<FeaturedMedia, "altText" | "caption" | "url">;
  hero: Hero;
  layout: Layout;
  topLevelParent: number | null;
};

export type AuthorPage = {
  id: number;
  seoHeadContent: string;
  description: string;
  image: string;
  title: string;
  link: string;
  slug: string;
};

export type MenuItem = {
  url: string;
  title: string;
  slug: string;
};
export type MenuItems = MenuItem[];

export type Taxonomy = {
  description: string;
  id: number;
  link: string;
  title: string;
  seoHeadContent: string;
  slug: string;
};

export type PaginatedArticleResponse = {
  articles: (Article | ArticleExcerpt)[];
  pageOutOfBounds?: boolean;
  totalCount: number;
  totalPages: number;
};

export type PaginatedPageResponse = {
  pages: Page[];
  totalCount: number;
  totalPages: number;
};

export type Term = Pick<
  SharedFields,
  | "content"
  | "id"
  | "disableAds"
  | "link"
  | "manualAdPlacement"
  | "regionRestriction"
  | "schemaJson"
  | "scripts"
  | "seoHeadContent"
  | "slug"
  | "styles"
  | "title"
> & {
  excerpt: string;
  featuredMedia: FeaturedMedia;
};
