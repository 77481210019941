// eslint-disable-next-line no-restricted-imports -- Required to parse complex HTML from WP
import HTMLReactParser, { DOMNode } from "html-react-parser";

import { ReplaceElements } from "custom-types/Wordpress";

import adInserter from "./adInserter";
import elementSwapper from "./elementSwapper";

export default (
  content: string,
  disableAds: boolean,
  manualAdPlacement: boolean,
  displayCarousel: boolean,
  adTargeting?: Record<string, string | string[] | undefined>,
  enableLazyLoad?: boolean,
) => {
  let activateElementSwapper = false;

  Object.keys(ReplaceElements).forEach((element) => {
    if (
      content.indexOf(
        ReplaceElements[element as keyof typeof ReplaceElements],
      ) > -1
    ) {
      activateElementSwapper = true;
    }
  });
  const parsedContent = HTMLReactParser(content, {
    replace: activateElementSwapper
      ? (node: DOMNode) => elementSwapper(node, adTargeting)
      : () => {},
    trim: true,
  }) as JSX.Element | JSX.Element[];

  let contentElements: JSX.Element[] = [];
  if (
    !Array.isArray(parsedContent) &&
    (parsedContent as JSX.Element).type === "html"
  ) {
    // if the first tag is HTML, we can assume it will have an accompanying body tag
    const content = parsedContent?.props?.children?.props?.children;

    contentElements = Array.isArray(content) ? content : [content];
  } else if (
    !Array.isArray(parsedContent) &&
    (parsedContent as JSX.Element).type !== "html"
  ) {
    contentElements = [parsedContent];
  } else if (Array.isArray(parsedContent)) {
    contentElements = parsedContent;
  }

  if (contentElements.length > 0 && !disableAds && !manualAdPlacement) {
    contentElements = adInserter(
      contentElements,
      displayCarousel,
      adTargeting,
      enableLazyLoad,
    );
  }

  return contentElements;
};
