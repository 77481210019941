import { useEffect, useRef } from "react";

import { useEventTracker } from "hooks/useEventTracker";

const getAnchorInAncestors = (
  e: HTMLElement,
): HTMLAnchorElement | undefined => {
  if (e.nodeName === "A") {
    return e as HTMLAnchorElement;
  }

  if (e.parentElement) {
    return getAnchorInAncestors(e.parentElement);
  }

  return undefined;
};

export default (): { articleContentRef: React.Ref<HTMLDivElement> } => {
  const articleContentRef = useRef<HTMLDivElement>(null);

  const { publishEvent } = useEventTracker();

  const trackArticleLinkClick = (e: HTMLAnchorElement) => {
    publishEvent({
      action: "click",
      category: "Link",
      label: `${e.innerText} ${e.href}`,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    const handleArticleContentClick = (e: any) => {
      const anchorElement = getAnchorInAncestors(e.target);
      if (anchorElement) {
        trackArticleLinkClick(anchorElement);
      }
    };

    if (articleContentRef.current) {
      articleContentRef.current.addEventListener(
        "click",
        handleArticleContentClick,
      );
    }
    return () => {
      if (articleContentRef.current) {
        articleContentRef.current.removeEventListener(
          "click",
          handleArticleContentClick,
        );
      }
    };
  }, [articleContentRef.current]);

  return { articleContentRef };
};
